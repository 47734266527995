import React from "react"
import { useTheme, CSSObject } from "@emotion/react"
import { AppTheme } from "pitch45-common/theme/app-theme.types"
import Loop45Logo from "../components/logo/logo"
import { semanticSpacing } from "../theme/spacing"
import { wrapSSR } from "../utils/wrap-ssr"

function getStyles(theme: AppTheme) {
  const styles = {
    CONTAINER: {
      minHeight: "100vh",
      padding: semanticSpacing.horizontal.narrow,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      background: theme.colors.background,
    },
    LOGO: {
      width: "30vw",
      color: "red",
    },
  }
  return styles as { [key in keyof typeof styles]: CSSObject }
}

export default function Home() {
  const theme = useTheme() as AppTheme
  const styles = getStyles(theme)
  return (
    <div css={styles.CONTAINER}>
      <span css={styles.LOGO}>
        <Loop45Logo size="large" />
      </span>
    </div>
  )
}

export const getServerSideProps = wrapSSR(() => {
  return {
    props: {},
  }
})
